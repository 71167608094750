import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const Sidebar = lazy(() => import("./components/Sidebar"));
const Navbar = lazy(() => import("./components/Navbar"));
const Loading = lazy(() => import("./components/Loading"));
const Home = lazy(() => import("./pages/home/Home"));
const Evaluation = lazy(() => import("./pages/evaluation/Evaluation"));
const Account = lazy(() => import("./pages/account/Account"));
const Admin = lazy(() => import("./pages/admin/Admin"));
const Financials = lazy(() => import("./pages/financials/Financials"));

function AppRoutes() {
  const isMobile = useMediaQuery({
    query: "(max-width: 640px)",
  });
  // const { t } = useTranslation();
  return (
    <>
      {!isMobile && <Sidebar />}
      {isMobile && <Navbar />}
      <Suspense fallback={<Loading />}>
        <div className="mt-20 sm:mt-0 grow">
          {/* <div className="bg-emerald-500 p-5 text-black font-medium text-center text-xl">
            {t("banner")}
          </div> */}
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/evaluation" element={<Evaluation />} />
            <Route path="/financials" element={<Financials />} />
            <Route path="/account" element={<Account />} />
            <Route path="/admin/panel/content" element={<Admin />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
      </Suspense>
    </>
  );
}

export default AppRoutes;
